import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { createBranch } from "datasource/Api";
import { CreateBranchType } from "datasource/Types";
import BranchForm from "./BranchForm";
import WithCheckPermission from "components/WithCheckPermission";
import { PERMISSIONS } from "config/permissions";
function CreateBranch() {
  
  const navigate = useNavigate();
  const { isPending, mutate } = useMutation({
    mutationFn: createBranch,

    onSuccess(data) {
      toast.success("new branch was created successfully", {
        autoClose: 2000,
        position: "bottom-right",
      });
      navigate("/branches");
    },
    onError(error){
      
      toast.error("something wrong , maybe the name is taken ", {
        autoClose: 2000,
        position: "bottom-right",
      });
    }
  });
  const initialValues = {
    name: "",
    merchant_reference: undefined
  };
  const handleSubmit = (values: CreateBranchType) => {
    mutate(values);
  };

  return (
    <BranchForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      isLoding={isPending}
      title="Create new branch"
    />
  );
}

export default WithCheckPermission(CreateBranch, PERMISSIONS.branch_management);


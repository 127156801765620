import shared from "styles/shared.module.css";
import { Paper } from "@mui/material";

import { ActivitiesTable } from "./ActivitiesTable";
import { getOrderActivities } from "datasource/Api";
import WithCheckPermission from "components/WithCheckPermission";
import { PERMISSIONS } from "config/permissions";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

const PaymentActivities = () => {
  const { id } = useParams();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["paymentActivities", id],
    queryFn: () => getOrderActivities(`${id}`),
  });

  return (
    <div className={shared.page}>
      <div className={shared.pageTitle}>Payment Activities </div>

      <Paper sx={{ padding: "20px" }}>
        <ActivitiesTable
          loading={isLoading}
          transactions={data?.data || []}
          refresh={refetch}
        />
      </Paper>
    </div>
  );
};

export default WithCheckPermission(
  PaymentActivities,
  PERMISSIONS.view_purchase
);

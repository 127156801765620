import React, { forwardRef } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { TextField } from "@mui/material";
function PhoneNumberInput({
  onChange,
  error,
  value,
  label,
  disabled,
  ...props
}: InputPropsType) {
  return (
    <div className="w-full">
      <PhoneInput
        className="w-full"
        countryCallingCodeEditable
        international
        withCountryCallingCode
        value={value}
        error={error}
        disabled={disabled}
        defaultCountry="KW"
        inputComponent={PhoneInputt}
        countries={["KW"]}
        addInternationalOption={false}
        onChange={onChange}
        placeholder="Enter phone number"
      />

      <div className="text-rose-500 text-[0.7rem] h-3 mt-1  ">
        {error ? error : ""}
      </div>
    </div>
  );
}

export default PhoneNumberInput;

type InputPropsType = React.InputHTMLAttributes<HTMLInputElement> & {
  value: string | any;
  label: string;
  onChange: (value: any) => void;
  error?: any;
};

const PhoneInputt = forwardRef((props: any, ref: any) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      label="Phone Number"
      variant="outlined"
      name="phone"
    />
  );
});

import { useEffect, useState } from "react";
import shared from "styles/shared.module.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useParams } from "react-router-dom";
import { Avatar, CircularProgress, Divider } from "@mui/material";
import { getOrderDetails } from "datasource/Api";
import styles from "./Order.module.css";
import { OrderDetails, OrderStatus, installmentStatus } from "datasource/Types";
import { dateFormatterWithMonthName } from "utils";

const MerchantDetails = () => {
  let { id } = useParams();

  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);

  useEffect(() => {
    if (!id) {
      return;
    }

    fetchContent(parseInt(id));
  }, [id]);

  const fetchContent = (orderId: number) => {
    setOrderDetails(null);

    getOrderDetails(orderId).then((res) => {
      setOrderDetails(res.data);
    });
  };

  if (!orderDetails) {
    return (
      <div className={shared.loaderWrapper}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
  const tagClass = (status: string) => {
    switch (status) {
      case installmentStatus.COMPLATED: {
        return shared.approved;
      }
      case OrderStatus.CAPTURED: {
        return shared.approved;
      }
      case OrderStatus.EXPIRED: {
        return shared.bast;
      }
      case installmentStatus.PENDING: {
        return shared.pending;
      }
      case installmentStatus.CANCELLED: {
        return shared.rejected;
      }

      default: {
        return shared.pending;
      }
    }
  };
  return (
    <div className={shared.page}>
      <div className={styles.page}>
        <div className={styles.merchant}>
          <Avatar src={""} />
          <div className={styles.merchantInfo}>
            <h6 className={styles.sectionTitle}>
              {orderDetails.merchant.name}
            </h6>
            <p className={styles.subInfo}>{orderDetails.merchant.id}</p>
          </div>
        </div>
        <Divider />
        <div className={styles.orderInfo}>
          <div className={""}>
            <h6 className={styles.orderId}>
              <div>Order ID {orderDetails.merchant_order_id} </div>{" "}
              <div className={styles.status}>
                <div className={tagClass(orderDetails.status)}>
                  {orderDetails.status}
                </div>
                {orderDetails.settled_status && (
                  <div className={tagClass(orderDetails.settled_status)}>
                    {orderDetails.settled_status}
                  </div>
                )}
              </div>
            </h6>
          </div>
          <p className={styles.subInfo}>
            DeemaOrder ID{" "}
            <span className={styles.infoText}>{orderDetails.id}</span>
          </p>
          <p className={styles.subInfo}>
            Ordered at{" "}
            <span className={styles.infoText}>
              {dateFormatterWithMonthName(orderDetails.created_at)}
            </span>
          </p>
        </div>
        <Divider />
        {orderDetails.customer && (
          <>
            {" "}
            <div className={styles.clientInformation}>
              <h6 className={styles.sectionTitle}>Client information</h6>

              <div className={styles.clientMainInfo}>
                <Avatar src={orderDetails.customer?.image} />
                <div className={styles.merchantInfo}>
                  <h6 className={styles.clientName}>
                    {orderDetails.customer?.first_name +
                      orderDetails.customer?.last_name}
                  </h6>
                  <p className={styles.clientNumber}>
                    {orderDetails.customer?.phone_number}
                  </p>
                </div>
              </div>
            </div>
            <Divider />
          </>
        )}

        {/* <div className={styles.paymentInfo}> */}
          {/* {orderDetails?.plan && (
            <div className={styles.spaceBetwenTitle}>
              {" "}
              <h6 className={styles.sectionTitle}>Payment plan information</h6>
              <div className={shared.secondary}>
                {orderDetails?.plan?.nick_name}
              </div>
            </div>
          )} */}
          {/* <div className={styles.amounts}>
            {orderDetails.installments?.map((installment, index) => (
              <div key={installment.id} className={styles.amountCard}>
                <div className={styles.amountTitle}>
                  <span>
                    {getCountNameByIndex(index)}
                    <div className={tagClass(installment.status)}>
                      {installment.status}
                    </div>
                  </span>

                  <p className={styles.amountNumber}>
                    {installment.amount} {orderDetails.currency_code}
                  </p>
                </div>
                <p className={styles.amountDate}>
                  {installment.completed_at ? "paied " : "Payment due"}{" "}
                  {dateFormatterWithMonthName(
                    installment.completed_at ?? installment.must_be_paid_at
                  )}
                </p>
              </div>
            ))}
          </div> */}
        {/* </div>00 */}
        {/* <Divid0er /> */}
        <div>
          <h6 className={styles.paymentDetails}>Payment details</h6>
          {orderDetails?.total_refunds > 0 && (
            <div className={styles.subInfo}>
              <p>Total Before refund</p>
              <p>
                {orderDetails?.amount + orderDetails?.total_refunds}
                {orderDetails.currency_code}
              </p>
            </div>
          )}
          {orderDetails?.total_refunds > 0 && (
            <div className={styles.refneded}>
              <p>Refnded </p>
              <p>
                <InfoOutlinedIcon sx={{ width: 20, marginRight: 1 }} />
                {orderDetails.total_refunds}
                {orderDetails.currency_code}
              </p>
            </div>
          )}
          <div className={styles.totalAmount}>
            <p>Total</p>
            <p>
              {orderDetails?.amount}
              {orderDetails.currency_code}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantDetails;
export const TEXT_NUMBERS = ["First", "Second", "Third", "Fourth"];

import styles from "./navbar.module.css";
import button from "styles/button.module.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/authReducer";
import { logoutApi } from "datasource/Api";
import { useMutation } from "@tanstack/react-query";
import PageLoader from "components/loaders/Loader";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";

const Navbar = () => {
  const dispatch = useDispatch();
  const merchantName = useSelector(
    ({ auth }: { auth: any }) => auth.user?.name
  );
  const subMerchants = useSelector((state: any) => state.auth.subMerchants);
  const isAggregator = useSelector((state: any) => state.auth.isAggregator);

  const activeSubMerchant = useSelector(
    (state: any) => state.auth.activeSubMerchant
  );

  const subMerchantChangeHandler = (evt: any) => {
    localStorage.setItem("activeSubMerchant", evt.target.value);
    window.location.reload();
  };

  const { isPending, mutate } = useMutation({
    mutationFn: logoutApi,

    onSuccess() {
      dispatch(logout());
    },
  });

  const openIntegrationFormHandler = () => {
    window.open("https://forms.gle/XqwroRwMLmkJmdgb6");
  };
  return (
    <>
      <div className={styles.navbar}>
        {isPending && <PageLoader />}
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <div className={styles.navPart}>
            <div className={styles.welcomeText}>Welcome to</div>
            <img src="/logo.svg" alt="Deema logo" width={100} height={30} />
          </div>
          {isAggregator && (
            <FormControl
              size="small"
              sx={{
                width: 200,
              }}
            >
              <InputLabel
                id="sub-merchant-list"
                sx={{
                  color: "white",
                  "&.Mui-focused": {
                    color: "white", // Ensure label stays white when focused
                  },
                }}
              >
                Sub Merchant
              </InputLabel>
              <Select
                sx={{
                  color: "white",
                  "& .MuiFormLabel-root": {
                    color: "white",
                  },
                  "& .MuiSelect-icon": {
                    color: "white",
                  },
                  "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white", // White border when focused
                    },
                  },
                }}
                // variant="outlined"
                labelId="sub-merchant-list"
                label="Sub Merchant"
                onChange={subMerchantChangeHandler}
                value={activeSubMerchant}
                placeholder="Sub Merchant"
              >
                {subMerchants.map((sm: any) => (
                  <MenuItem key={sm.id} value={sm.id}>
                    {sm.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        <div className={styles.navPart}>
          <div
            className={styles.integrateWithDeema}
            onClick={openIntegrationFormHandler}
          >
            <AppShortcutIcon />
            <span>Integrate with deema</span>
          </div>
          <div className={styles.merchantName}>{merchantName}</div>
          <button
            onClick={() => mutate()}
            className={`${button.buttonSecondary} ${button.buttonSm}`}
          >
            Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default Navbar;

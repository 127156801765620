import styles from "./users.module.css";
import { Button, Paper, Stack, TableContainer } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import shared from "styles/shared.module.css";
import { getMerchentUsers, toggleUserHidden } from "datasource/Api";
import { useMutation, useQuery } from "@tanstack/react-query";
import PageLoader from "components/loaders/Loader";
import Loader from "core/Loader";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Edit } from "@mui/icons-material";
import WithCheckPermission from "components/WithCheckPermission";
import { PERMISSIONS } from "config/permissions";
const Users = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["users"],
    queryFn: () => getMerchentUsers(),
  });
  const { isPending, mutate: mutateToggleUserHidden } = useMutation({
    mutationFn: toggleUserHidden,

    onSuccess(data) {
      refetch();
      toast.success(
        `${data.data.full_name} was ${
          data.data.is_active ? "deactivated" : "activated"
        } successfully`,
        {
          autoClose: 2000,
          position: "bottom-right",
        }
      );
    },
  });
  const navigate = useNavigate();

  return (
    <div className={shared.page}>
      {isPending && <PageLoader />}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {" "}
        <div className={shared.pageTitle}> Users</div>{" "}
        <Link to={"/users/new"}>
          <Button variant="outlined">Create new user</Button>
        </Link>
      </Stack>
      <Paper sx={{ padding: "20px" }}>
        <>
          <TableContainer>
            {!isLoading && (
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>user id </TableCell>
                    <TableCell>full name </TableCell>

                    <TableCell>email</TableCell>
                    <TableCell align="center">status</TableCell>
                    <TableCell align="center">acrions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{row.full_name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="center">
                        <div
                          className={
                            !row.is_active ? styles.desActive : styles.active
                          }
                        >
                          {row.is_active ? "Active" : "inActive"}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {!row.is_owner ? (
                          <Stack
                            direction="row"
                            justifyContent="center"
                            gap={1}
                            alignItems="center"
                          >
                            <Button
                              onClick={() => {
                                mutateToggleUserHidden(row.id);
                              }}
                              size="small"
                              variant="outlined"
                            >
                              {!row.is_active ? "activate" : "deactivates"}
                            </Button>
                            <Button
                              endIcon={<Edit fontSize="small" />}
                              onClick={() => {
                                navigate(`/users/${row.id}`);
                              }}
                              size="small"
                              variant="outlined"
                            >
                              Edit
                            </Button>
                          </Stack>
                        ):""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {isLoading && <Loader />}
          </TableContainer>
        </>
      </Paper>
    </div>
  );
};

export default WithCheckPermission(Users, PERMISSIONS.user_management);

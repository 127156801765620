import { FC, useMemo } from "react";
import { ACTOR_TYPE, ActivityStatus, Transaction } from "datasource/Types";

import shared from "styles/shared.module.css";

import GenericTable, { TableHeadType } from "components/tables/GenericTable";
import { dateFormatter, formatAMPM } from "utils";
import { Box, Stack } from "@mui/material";
import styles from "./activity.module.css";

type ActivitiesTableProps = {
  transactions: Transaction[];
  refresh: () => void;
  loading: boolean;
};

const actors: { [key: string]: string } = {
  [ACTOR_TYPE.CUSTOMER]: "Customer",
  [ACTOR_TYPE.MERCHANT]: "Merchant",
};
export const tagClass = (purchaseStatus: string) => {
  switch (purchaseStatus) {
    case ActivityStatus.FULLY_CAPTURED||ActivityStatus.PARTIALLY_CAPTURED: {
      return styles.captured;
    }
    case ActivityStatus.PARTIALLY_REFUNDED||ActivityStatus.FULLY_REFUNDED: {
      return styles.cancelled;
    }
    case ActivityStatus.ORDER_SETTLED||ActivityStatus.EXPIRED: {
      return shared.bast;
    }
    case ActivityStatus.PENDING: {
      return styles.pending;
    }
   
    default: {
      return styles.pending;
    }
  }
};
export const ActivitiesTable: FC<ActivitiesTableProps> = ({
  transactions,
  loading,
}) => {
  const heads = useMemo<TableHeadType[]>(() => {
    return [
      {
        key: "id",
        name: " ID",
        align: "left",
      },
      
      {
        key: "created_at",
        name: "Date and time",
        align: "left",
        renderCell: (created_at) => (
          <>
            <div>{dateFormatter(created_at)}</div>
            <div className={shared.thin}>{formatAMPM(created_at)}</div>
          </>
        ),
      },
      {
        key: "type",
        name: "type",
        align: "left",
        renderCell: (value) => (
          <div
            className={tagClass(value)}
            style={{ textTransform: "capitalize" }}
          >
            {value}
          </div>
        ),
      },
      {
        key: "activityAmount",
        name: " activity amount",
        align: "left",
        renderCell: (row) => <>{row.activity_amount + " KWD"}</>,
      },

      {
        key: "amountBeforeAction",
        name: "amount before action",
        align: "left",
        renderCell: (row) => <>{row.amount_before_action + " KWD"}</>,
      },
      {
        key: "amountAfterAction",
        name: "amount after action",
        align: "left",
        renderCell: (row) => <>{row.amount_after_action + " KWD"}</>,
      },
      {
        key: "actor",
        name: "actor Info ",
        align: "left",
        renderCell: (actor) => (
          <Stack gap={1}>
            <Box
              width={"fit-content"}
              className={`${
                actor.actor_type === ACTOR_TYPE.CUSTOMER
                  ? shared.secondary
                  : shared.approved
              } ${shared.thin}`}
            >
              {actors[actor.actor_type]}
            </Box>
            <div className={shared.thin}>
              {" "}
              {actor?.name || actor.first_name + " " + actor.last_name}
            </div>
            <div className={shared.thin}>{actor.phone_number}</div>
          </Stack>
        ),
      },
    ];
  }, []);

  return (
    <>
      <GenericTable
        loading={loading}
        heads={heads}
        rows={
          transactions?.map((row) => ({
            id: row.id,
            purchase_id: row.purchase_id,
            type: row.type,
            activityAmount: row,
            created_at: row.created_at,
            amountBeforeAction: row,
            amountAfterAction: row,
            actor: { ...row.actor, actor_type: row.actor_type },
            actorPhoneNumber: row.actor.phone_number,
          })) || []
        }
      />
    </>
  );
};

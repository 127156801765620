import React, { ChangeEvent, useState } from "react";
import shared from "styles/shared.module.css";
import styles from "./orders.module.css";
import {
  Autocomplete,
  Box,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { OrderPlace, OrderStatus } from "datasource/Types";
import { Pagination } from "components/pagination";
import { OrdersTable } from "./OrdersTable";
import { PER_PAGE_DEFAULT } from "config/base";
import { getAvailableBranches, getOrders } from "datasource/Api";
import WithCheckPermission from "components/WithCheckPermission";
import { PERMISSIONS } from "config/permissions";
import { useQuery } from "@tanstack/react-query";

import dayjs from "dayjs";
import { getFormatedDate } from "utils/date";
import DateRangeInput from "components/inputs/DateRangeInput";
import { DateRange } from "@mui/x-date-pickers-pro";

const Orders = () => {
  const [orderType, setOrderType] = useState<string>("ALL");
  const [search, setSearch] = useState("");
  const [orderPlace, setOrderPlace] = useState("ALL");
  const [branch, setBranch] = useState("ALL");
  const [page, setPage] = useState(1);
  const [date, setDate] = useState<DateRange<dayjs.Dayjs>>([null, null]);

  const {
    data: ordersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "orders",
      search,
      orderPlace,
      branch,
      page,
      orderType,
      [...date],
    ],
    queryFn: () =>
      getOrders({
        pagingData: { page: page, perPage: PER_PAGE_DEFAULT },
        filters: {
          search,
          type: orderPlace,
          branch_id: branch,
          order_status: orderType,
          from_date: getFormatedDate(date[0]?.toDate()),
          to_date: getFormatedDate(date[1]?.toDate()),
        },
      }),
  });

  const { data } = useQuery({
    queryKey: ["available-branches"],
    queryFn: () => getAvailableBranches(),
  });

  const handleOrderTypeChanged = (event: SelectChangeEvent) => {
    setOrderType(event.target.value);
    setPage(1);
  };

  const handleDateChanged = (date: any) => {
    setDate(date);
    setPage(1);
  };

  const handleSearchChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearch(search);
    setPage(1);
  };

  const handleOrderPlaceChanged = (event: SelectChangeEvent) => {
    setOrderPlace(event.target.value);
    setPage(1);
    setBranch("");
  };

  const handleBranchChanged = (value: string) => {
    setBranch(value);
    setPage(1);
  };

  const pageChanged = (event: ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  return (
    <div className={shared.page}>
      <div className={shared.pageTitle}>Captured Orders</div>
      <div className={styles.filters}>
        <TextField
          sx={{ flex: 1 }}
          label="Search by key words"
          variant="outlined"
          placeholder="Search by client id name, order ID ..."
          value={search}
          size="small"
          onChange={handleSearchChanged}
          InputProps={{
            endAdornment: <SearchIcon style={{ color: "grey" }} />,
          }}
        />
        <DateRangeInput value={date} onChange={handleDateChanged} />

        <Select
          style={{ width: 150 }}
          labelId="Order status"
          size="small"
          onChange={handleOrderTypeChanged}
          value={orderType}
        >
          <MenuItem value={"ALL"}>All statuses</MenuItem>
          <MenuItem value={OrderStatus.FULLY_CAPTURED}>Fully Captured</MenuItem>
          <MenuItem value={OrderStatus.PARTIALLY_CAPTURED}>
            Partially Captured
          </MenuItem>
          <MenuItem value={OrderStatus.FULLY_REFUNDED}>Fully Refunded</MenuItem>
          <MenuItem value={OrderStatus.PARTIALLY_REFUNDED}>
            Partially refunded
          </MenuItem>
          <MenuItem value={OrderStatus.DECLINED}>Declined</MenuItem>
        </Select>
        <Select
          style={{ width: 150 }}
          labelId="Order type"
          size="small"
          onChange={handleOrderPlaceChanged}
          value={orderPlace}
        >
          <MenuItem value={"ALL"}>All types</MenuItem>
          <MenuItem value={OrderPlace.ONLINE}>Online</MenuItem>
          <MenuItem value={OrderPlace.STORE}>In-Store</MenuItem>
        </Select>
        <Autocomplete
          disablePortal
          id="merchant_id"
          options={data?.data || []}
          size="small"
          style={{ width: 150 }}
          multiple={false}
          getOptionLabel={(op) => op.name}
          disabled={orderPlace === OrderPlace.ONLINE}
          onChange={(e, value) => {
            if (value) {
              handleBranchChanged(`${value?.id}`);
            }
          }}
          renderInput={(params) => <TextField {...params} label="Branch" />}
        />
      </div>
      <div className={shared.spacer} />
      <Paper sx={{ padding: "20px" }}>
        <OrdersTable
          loading={isLoading}
          orders={ordersData?.data?.data || []}
          refresh={refetch}
        />
        <Box className={styles.paging}>
          <div className={shared.showingResults}>
            {ordersData?.data?.data.length} results out of{" "}
            {ordersData?.data?.total}
          </div>
          <Pagination
            defaultPage={page}
            count={ordersData?.data?.last_page || 0}
            onChange={pageChanged}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default WithCheckPermission(Orders, PERMISSIONS.view_purchase);

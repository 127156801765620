import React, { FC, useState } from "react";
import typography from "styles/typography.module.css";
import button from "styles/button.module.css";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";

interface RefundModalProps {
  wallet: string;
  orderId: number;
  currency: string;
  onConfirm: (refundValue: number) => void;
  submitting: boolean;
  confirmed: boolean;
  maxRefund: number;
}

const RefundModal: FC<RefundModalProps> = ({
  wallet,
  orderId,
  onConfirm,
  maxRefund,
  submitting,
  currency,
  confirmed,
}) => {
  const [refundAmount, setRefundAmount] = useState<number | null>(null);
  const amountError =
    !!refundAmount && (refundAmount > maxRefund || refundAmount == maxRefund);
  const handleSubmit = () => {
    if (amountError || !refundAmount) {
      return;
    }
    onConfirm(refundAmount);
  };

  const handleAmountChange = (e: any) => {
    const val = e.target.value;
    const regex = /^\d*\.?\d{0,3}$/;
    if (regex.test(val)) {
      setRefundAmount(val);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        gap: 14,
        width: 415,
        marginBottom: 14,
      }}
    >
      {!confirmed ? (
        <>
          <h2
            className={`${typography.title} ${typography.textPrimary}`}
            style={{ fontWeight: 500 }}
          >
            Partial Refund
          </h2>
          <div style={{ fontWeight: 300 }}>
            You are about to refund part of the total amount of order number{" "}
            {orderId}
          </div>
          <div
            style={{ alignSelf: "start" }}
            className={typography.regularText}
          >
            Please input the refund amount
          </div>
          <TextField
            label="Refund amount"
            id="outlined-start-adornment"
            onChange={handleAmountChange}
            error={amountError}
            helperText={
              amountError
                ? `The refund amount should be less than the order amount`
                : ""
            }
            size="small"
            variant="outlined"
            type="number"
            value={refundAmount}
            sx={{ width: "100%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{currency}</InputAdornment>
              ),
            }}
          />
          <button
            disabled={submitting || amountError || !refundAmount}
            onClick={handleSubmit}
            style={{ marginTop: 5 }}
            className={`${button.buttonPrimary} ${
              submitting || amountError || !refundAmount ? button.disabled : ""
            }`}
          >
            Confirm refund
          </button>
        </>
      ) : (
        <>
          <h2
            className={`${typography.title} ${typography.regularText}`}
            style={{ fontWeight: 500 }}
          >
            Partial refund successful
          </h2>
          <div style={{ fontWeight: 300 }}>
            The order number {orderId} has been edited successfully,
            installments updated and refund issued if needed
          </div>
          <div className={typography.regularText}>
            Your {wallet} wallet was debited
          </div>
        </>
      )}
    </div>
  );
};

export default RefundModal;

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import shared from "styles/shared.module.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import Loader from "components/loaders/Loader";
import { CreateUserType } from "datasource/Types";
import { useQuery } from "@tanstack/react-query";
import { getMerchentBranches, getRoles } from "datasource/Api";
import { useSelector } from "react-redux";
function UserForm({
  initialValues,
  isEdit,
  handleSubmit,
  isLoding,
  title,
}: {
  initialValues: CreateUserType;
  title: string;
  isLoding: boolean;
  handleSubmit: (values: CreateUserType) => void;
  isEdit?: boolean;
}) {
  const isAggregator = useSelector((state: any) => state.auth.isAggregator);
  const ValidationSchema = Yup.object().shape({
    full_name: Yup.string().required("required"),
    email: Yup.string().required("required").email(),
    role_id: isAggregator ? Yup.string().optional() :Yup.string().required("required"),
    branches: Yup.array().required("required").min(1),
  });
  const { data: branchesData } = useQuery({
    queryKey: ["branches"],
    queryFn: () => getMerchentBranches(),
  });
  const { data: rolesData } = useQuery({
    queryKey: ["roles"],
    queryFn: () => getRoles(),
  });

  return (
    <div className={shared.page}>
      {isLoding && <Loader />}
      <Box>
        <Typography variant="h5" mb={5} fontWeight="700">
          {title}
        </Typography>
        <Formik
          validateOnChange={false}
          validateOnBlur
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              <Grid spacing={3} container>
                <Grid item xs={12} lg={5}>
                  {" "}
                  <Grid spacing={3} container mb={4}>
                    <Grid item xs={12}>
                      <TextField
                        {...props.getFieldProps({ name: "full_name" })}
                        variant="outlined"
                        error={!!props.errors.full_name}
                        fullWidth
                        label="user full name "
                        disabled={isEdit}
                        name="full_name"
                      />
                      <FormHelperText
                        sx={(theme) => ({
                          color: theme.palette.error.main,
                        })}
                      >
                        {props.errors.full_name}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        {...props.getFieldProps({ name: "email" })}
                        variant="outlined"
                        disabled={isEdit}
                        error={!!props.errors.email}
                        fullWidth
                        label="user email "
                        name="email"
                      />
                      <FormHelperText
                        sx={(theme) => ({
                          color: theme.palette.error.main,
                        })}
                      >
                        {props.errors.email}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="branches">branches</InputLabel>
                        <Select
                          {...props.getFieldProps({ name: "branches" })}
                          variant="outlined"
                          error={!!props.errors.branches}
                          name="branches"
                          labelId="branches"
                          label="branches"
                          fullWidth
                          multiple
                        >
                          {branchesData?.data.map((branch) => (
                            <MenuItem key={branch.id} value={branch.id}>
                              {branch.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormHelperText
                        sx={(theme) => ({
                          color: theme.palette.error.main,
                        })}
                      >
                        {props.errors.branches}
                      </FormHelperText>
                    </Grid>
                    {!isAggregator && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="role_id">role</InputLabel>
                          <Select
                            {...props.getFieldProps({ name: "role_id" })}
                            variant="outlined"
                            error={!!props.errors.role_id}
                            name="role_id"
                            labelId="role_id"
                            label="role"
                            fullWidth
                          >
                            {rolesData?.data.map((role) => (
                              <MenuItem value={role.id}>{role.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormHelperText
                          sx={(theme) => ({
                            color: theme.palette.error.main,
                          })}
                        >
                          {props.errors.role_id}
                        </FormHelperText>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        sx={{ boxShadow: "none" }}
                        size="large"
                        variant="contained"
                      >
                        save user
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
}

export default UserForm;

import React, { FC, useMemo, useState } from "react";
import { PaymentPlan, OrderStatus, OrderModel } from "datasource/Types";

import { currencyCountry, dateFormatter, formatAMPM } from "utils";
import styles from "./orders.module.css";
import { MenuItem, Typography } from "@mui/material";
import shared from "styles/shared.module.css";
import { BasicModal } from "shared/modal";
import { CancelModalConfirm, RefundModal } from "components/modals";
import { cancelOrderRequest, refundOrderRequest } from "datasource/Api";
import { toast } from "react-toastify";
import usePermissionsChecker from "utils/hooks/usePermissionsChecker";

import { PERMISSIONS } from "config/permissions";
import GenericTable, { TableHeadType } from "components/tables/GenericTable";
import TableMenu from "components/menu/TableMenu";
import { Link, useNavigate } from "react-router-dom";
export const tagClass = (purchaseStatus: string) => {
  switch (purchaseStatus) {
    case OrderStatus.FULLY_CAPTURED || OrderStatus.PARTIALLY_CAPTURED: {
      return styles.captured;
    }
    case OrderStatus.PARTIALLY_REFUNDED: {
      return styles.cancelled;
    }
    case OrderStatus.FULLY_REFUNDED: {
      return styles.cancelled;
    }
    case OrderStatus.ORDER_SETTLED || OrderStatus.EXPIRED: {
      return shared.bast;
    }
    case OrderStatus.PENDING: {
      return styles.pending;
    }
    case PaymentPlan.ONE_PAYMENT: {
      return styles.onePayment;
    }
    case PaymentPlan.THREE_PAYMENTS: {
      return styles.theePayments;
    }
    default: {
      return styles.captured;
    }
  }
};
type OrderesTableProps = {
  orders: OrderModel[];
  refresh: () => void;
  loading: boolean;
};

export const OrdersTable: FC<OrderesTableProps> = ({
  orders,
  refresh,
  loading,
}) => {
  const [cancelModal, setCancelModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderModel | null>(null);
  const [submiting, setSubmitting] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [refundSuccess, setRefundSuccess] = useState(false);
  const [redundModal, setRefundModal] = useState(false);
  const { hasPermissionTo } = usePermissionsChecker();

  const confirmCancellation = (purchaseId: number) => {
    setSubmitting(true);
    cancelOrderRequest(purchaseId)
      .then(() => {
        setCancelSuccess(true);
      })
      .catch(() => {
        toast.error("Unexpected error, please try again", {
          position: "top-right",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const confirmRefund = (purchaseId: number, refundAmount: number) => {
    setSubmitting(true);
    refundOrderRequest(purchaseId, refundAmount)
      .then(() => {
        setRefundSuccess(true);
      })
      .catch(() => {
        toast.error("Unexpected error, please try again", {
          position: "top-right",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const closeCancelModal = () => {
    setCancelModal(false);
    if (cancelSuccess) {
      refresh();
    }
    setTimeout(() => {
      setCancelSuccess(false);
      setSelectedOrder(null);
    }, 500);
  };

  const closeRefundModal = () => {
    setRefundModal(false);
    if (refundSuccess) {
      refresh();
    }
    setTimeout(() => {
      setRefundSuccess(false);
      setSelectedOrder(null);
    }, 500);
  };
  const nav = useNavigate();
  const heads = useMemo<TableHeadType[]>(() => {
    return [
      {
        key: "orderID",
        name: "Order ID",
        align: "left",
      },
      {
        key: "deemaOrderID",
        name: "Deema Order ID",
        align: "left",
      },
      {
        key: "date",
        name: "Date and time",
        align: "left",
        renderCell: (row) => (
          <>
            <div>{dateFormatter(row.order_date_time)}</div>
            <div className={styles.thin}>{formatAMPM(row.order_date_time)}</div>
          </>
        ),
      },
      {
        key: "orderStatus",
        name: "Order Status",
        align: "left",
        renderCell: (value) => (
          <div
            className={tagClass(value)}
            style={{ textTransform: "capitalize" }}
          >
            {value}
          </div>
        ),
      },
      // {
      //   key: "paymentPlan",
      //   name: "Payment Plan",
      //   align: "left",
      //   renderCell: (value) => (
      //     <>
      //       <div className={tagClass(value)}>{value}</div>
      //     </>
      //   ),
      // },

      {
        key: "branch",
        name: "Branch",
        align: "left",
      },
      {
        key: "customerInfo",
        name: "Customer Info",
        align: "left",
        renderCell: (row) => (
          <>
            <div>{row.customer_name}</div>
            <div className={styles.thin}>
              {row.customer_phone_number ? "+" : ""}
              {row.customer_phone_number}
            </div>
          </>
        ),
      },
      {
        key: "amount",
        name: "Amount(s)",
        align: "left",
      },
      {
        key: "actions",
        name: "Actions",
        action: () => {},
        align: "left",
        renderCell: (row) => {
          return (
            <TableMenu>
              <>
                {hasPermissionTo(PERMISSIONS.cancel_purchase) && (
                  <MenuItem
                    onClick={() => {
                      setSelectedOrder(row);
                      setCancelModal(true);
                    }}
                  >
                    {" "}
                    <Typography color="error">cancel</Typography>
                  </MenuItem>
                )}
                {hasPermissionTo(PERMISSIONS.refund_purchase) && (
                  <MenuItem
                    onClick={() => {
                      setSelectedOrder(row);
                      setRefundModal(true);
                    }}
                  >
                    <Typography color="primary.main">refund</Typography>
                  </MenuItem>
                )}
                <MenuItem onClick={() => nav(`/activities/${row.id}`)}>
                  <Typography color="success">activities</Typography>
                </MenuItem>
                <MenuItem onClick={() => nav(`/orders/${row.id}`)}>
                  <Typography color="success">details</Typography>
                </MenuItem>
              </>
            </TableMenu>
          );
        },
      },
    ];
  }, []);

  return (
    <>
      <GenericTable
        loading={loading}
        heads={heads}
        // onRowClick={(id) => nav(`/orders/${id}`)}
        rows={
          orders?.map((row: any) => ({
            orderID: row.merchant_order_id,
            id: row.id,
            deemaOrderID: row.id,
            date: row,
            orderStatus: row.status,
            paymentPlan: row.payment_plan,
            actions: row,
            customerInfo: row,
            branch: row.branch_name,
            amount: ` ${row.amount} ${row.currency_code}`,
          })) || []
        }
      />

      {selectedOrder && (
        <BasicModal
          open={cancelModal}
          closeOnClickOutside
          closeText={cancelSuccess ? "Ok" : "Don't cancel"}
          onClose={closeCancelModal}
        >
          <CancelModalConfirm
            confirmed={cancelSuccess}
            submitting={submiting}
            onConfirm={() => confirmCancellation(selectedOrder.id)}
            wallet={`${currencyCountry(selectedOrder?.currency_code)} - ${
              selectedOrder?.currency_code
            }`}
            customerName={selectedOrder?.customer_name}
            orderId={selectedOrder.merchant_order_id}
          />
        </BasicModal>
      )}
      {selectedOrder && (
        <BasicModal
          open={redundModal}
          closeOnClickOutside
          closeText={refundSuccess ? "Ok" : "Cancel"}
          onClose={closeRefundModal}
        >
          <RefundModal
            confirmed={refundSuccess}
            submitting={submiting}
            maxRefund={selectedOrder.amount}
            currency={selectedOrder.currency_code}
            onConfirm={(refundAmount) =>
              confirmRefund(selectedOrder.id, refundAmount)
            }
            wallet={`${currencyCountry(selectedOrder.currency_code)} - ${
              selectedOrder.currency_code
            }`}
            orderId={selectedOrder.merchant_order_id}
          />
        </BasicModal>
      )}
    </>
  );
};

import { getSubMerchants, getUserDetails } from "datasource/Api";
import { MerchantStatus } from "datasource/Types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  savePermissinos,
  setSubMerchants,
} from "store/authReducer";

function useGetPermissinos() {
  const dispatch = useDispatch();

  const approvedMerchant =
    (useSelector(({ auth }: { auth: any }) => auth.user?.status) || null) ===
    MerchantStatus.APPROVED;
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getUserDetails();
      dispatch(
        savePermissinos(res?.data?.roles[0]?.permissions?.map((per) => per.key))
      );
      const isAggregator = !!localStorage.getItem("isAggregator");
      if (isAggregator) {
        const subMerchantsData = await getSubMerchants();
        const { data } = subMerchantsData.data;
        dispatch(setSubMerchants(data));
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (approvedMerchant) {
      fetchData();
    }
  }, [approvedMerchant, fetchData]);
  return { loading };
}

export default useGetPermissinos;

import styles from "./sidebar.module.css";
import { Box, Drawer, Toolbar } from "@mui/material";
import { NavLink } from "react-router-dom";
import GridViewIcon from "@mui/icons-material/GridView";
import PhishingIcon from "@mui/icons-material/Phishing";
import { useSelector } from "react-redux";
import { MerchantStatus } from "datasource/Types";
import WalletIcon from "@mui/icons-material/Wallet";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import {
  AdminPanelSettings,
  QrCodeScannerOutlined,
  Store,
  TaskAlt,
  
} from "@mui/icons-material";
import usePermissionsChecker from "utils/hooks/usePermissionsChecker";
import { PERMISSIONS } from "config/permissions";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
const drawerWidth = 240;

const Sidebar = () => {
  const merchantStatus = useSelector(
    ({ auth }: { auth: any }) => auth.user?.status
  );

  const approvedMerchant =
    (useSelector(({ auth }: { auth: any }) => auth.user?.status) || null) ===
    MerchantStatus.APPROVED;
  const { hasPermissionTo } = usePermissionsChecker();

  const navLinkClass = ({ isActive }: { isActive: boolean }) =>
    `${styles.listItem} ${isActive ? styles.active : ""}`;

  const showNavigations = merchantStatus === MerchantStatus.APPROVED;
  const sidebarItems = [
    {
      name: "Captured Orderes",
      link: "/orders",
      icon: ShoppingCartIcon,
      Permission: PERMISSIONS.view_purchase,
    },
    {
      name: "Pending Orders",
      link: "/pending-orders",
      icon: ProductionQuantityLimitsIcon,
      Permission: PERMISSIONS.view_purchase,
    },
    {
      name: "Place InStore Order",
      link: "/create-order",
      icon: QrCodeScannerOutlined,
      Permission: PERMISSIONS.place_in_store_purchase,
    },
    {
      name: "Settlements ",
      link: "/settlements",
      icon: PaymentsOutlinedIcon,
      Permission: PERMISSIONS.view_settlement,
    },
    {
      name: "Webhook ",
      link: "/webhook",
      icon: PhishingIcon,
      Permission: PERMISSIONS.account_configuration,
    },
    {
      name: "Wallets ",
      link: "/wallets",

      icon: WalletIcon,
      Permission: PERMISSIONS.view_transaction,
    },
   
  
    {
      name: " Branches ",
      link: "/branches",
      icon: Store,
      Permission: PERMISSIONS.branch_management,
    },
    {
      name: "Users ",
      link: "/users",
      icon: AdminPanelSettings,
      Permission: PERMISSIONS.user_management,
    },
    {
      name: "roles ",
      link: "/roles",
      icon: TaskAlt,
      Permission: PERMISSIONS.role_management,
    },
   
  ];
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar sx={{ height: 92 }} />
      <Box
        sx={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div className={styles.listWrapper}>
          {!approvedMerchant ||
            (hasPermissionTo(PERMISSIONS.dashboard_statistics) && (
              <NavLink className={navLinkClass} to="/">
                <GridViewIcon style={{ fontSize: 28 }} />
                Home
              </NavLink>
            ))}
          {showNavigations && (
            <>
              {sidebarItems.map(
                (item) =>
                  hasPermissionTo(item.Permission) && (
                    <NavLink
                      key={item.name}
                      className={navLinkClass}
                      to={item.link}
                    >
                      <item.icon style={{ fontSize: 28 }} />
                      {item.name}
                    </NavLink>
                  )
              )}
            </>
          )}
        </div>
      </Box>
    </Drawer>
  );
};

export default Sidebar;

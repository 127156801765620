import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./login.module.css";
import icons from "styles/icons.module.css";
import { TextField } from "@mui/material";
import button from "styles/button.module.css";
import {
  forgetPasswordRequestOTP,
  forgetPasswordSubmitPasswords,
  forgetPasswordVerifyOTP,
} from "datasource/Api";
import { ArrowBack } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import OtpInput, { OTPInputProps } from "react-otp-input";
import { toast } from "react-toastify";

const EMAIL_REGEX = /\S+@\S+\.\S+/;

export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;

enum Steps {
  EMAIL,
  VERIFICATION,
  SET_PASSWORD,
}

const ForgetPassword = () => {
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");

  const [activeStep, setActiveStep] = useState(Steps.EMAIL);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [otpToken, setOtpToken] = useState("");

  const [passwords, setPasswords] = useState({
    password: "",
    password_confirmation: "",
  });

  const handleEmailChanged = (event: any) => {
    const email = event.target.value;
    const validEmail = EMAIL_REGEX.test(email);
    setEmailError(!validEmail);
    setEmail(email);
  };

  const submitEmailHandler = async () => {
    try {
      setLoading(true);
      const result = await forgetPasswordRequestOTP({ email });
      const { otp_token } = result.data.data;
      setOtpToken(otp_token);
      setActiveStep(Steps.VERIFICATION);
    } catch (err) {
      console.log(err, "ERROR");
    } finally {
      setLoading(false);
    }
  };
  const submitOTPHandler = async () => {
    try {
      setLoading(true);
      await forgetPasswordVerifyOTP({ otp, token: otpToken });

      setActiveStep(Steps.SET_PASSWORD);
    } catch (err) {
      console.log(err, "ERROR");
    } finally {
      setLoading(false);
    }
  };

  const submitPasswordsHandler = async () => {
    try {
      setLoading(true);
      await forgetPasswordSubmitPasswords({
        token: otpToken,
        password: passwords.password,
        password_confirmation: passwords.password_confirmation,
      });
      toast.success("Password changed successfully");
      navigate("/login");
    } catch (err) {
      console.log(err, "ERROR");
    } finally {
      setLoading(false);
    }
  };

  let activeStepText = "";

  if (activeStep === Steps.EMAIL) activeStepText = "Please enter your email";
  else if (activeStep === Steps.VERIFICATION)
    activeStepText = `We’ve sent an OTP to ${email}`;
  else activeStepText = "Enter your new password";

  let content = (
    <TextField
      fullWidth
      onChange={handleEmailChanged}
      value={email}
      error={emailError}
      helperText={emailError ? "Please enter a valid email" : ""}
      label="Business Email"
      size="small"
    />
  );

  if (activeStep === Steps.VERIFICATION) {
    content = (
      <VerificationInputs
        onResendHandler={submitEmailHandler}
        onSubmitHandler={submitOTPHandler}
        otp={otp}
        setOtp={setOtp}
      />
    );
  }

  const matchPasswordsExpression =
    passwords.password &&
    passwords.password_confirmation &&
    passwords.password !== passwords.password_confirmation;

  const isValidPasswordRegex = PASSWORD_REGEX.test(passwords.password);

  if (activeStep === Steps.SET_PASSWORD) {
    content = (
      <>
        <TextField
          fullWidth
          onChange={(ev) =>
            setPasswords((prev) => ({ ...prev, password: ev.target.value }))
          }
          value={passwords.password}
          label="Password"
          type="password"
          size="small"
        />
        <TextField
          fullWidth
          type="password"
          onChange={(ev) =>
            setPasswords((prev) => ({
              ...prev,
              password_confirmation: ev.target.value,
            }))
          }
          value={passwords.password_confirmation}
          error={!!matchPasswordsExpression}
          helperText={
            isValidPasswordRegex ? (
              matchPasswordsExpression ? (
                "Passwords Must Match"
              ) : (
                ""
              )
            ) : (
              <ul>
                <li>Password must be at least 8 characters long.</li>
                <li>
                  Password must contain at least one uppercase and one lowercase
                  letter.
                </li>
                <li>Password must contain at least one special character.</li>
              </ul>
            )
          }
          label="Password Confirmation"
          size="small"
        />
      </>
    );
  }

  const submitHandler = async () => {
    if (activeStep === Steps.EMAIL) {
      if (!emailError) {
        await submitEmailHandler();
      }
    } else if (activeStep === Steps.VERIFICATION) await submitOTPHandler();
    else {
      if (!matchPasswordsExpression && isValidPasswordRegex) {
        await submitPasswordsHandler();
      }
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.loginBg} />
      <div className={styles.loginSection}>
        <div className={styles.loginContent}>
          <div
            className={styles.backWrapper}
            onClick={() => {
              if (activeStep === Steps.EMAIL) navigate("/login");
              else if (activeStep === Steps.VERIFICATION)
                setActiveStep(Steps.EMAIL);
              else setActiveStep(Steps.VERIFICATION);
            }}
          >
            <ArrowBack />
            <span>Back</span>
          </div>
          <i className={`${icons.coloredLogo} ${styles.logo}`} />
          <p className={styles.loginText}>
            Forget Password,
            <br />
            <p
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {activeStepText}
            </p>
          </p>
          {content}
          <button
            disabled={
              (!email && activeStep === Steps.EMAIL) ||
              (otp.length !== 6 && activeStep === Steps.VERIFICATION) ||
              (activeStep === Steps.SET_PASSWORD && !!matchPasswordsExpression && isValidPasswordRegex)
            }
            onClick={submitHandler}
            style={{
              marginTop: 10,
              opacity: email || otp.length === 6 || (!matchPasswordsExpression && isValidPasswordRegex) ? 1 : 0.5,
            }}
            className={`${button.buttonPrimary} ${button.block} ${
              loading ? button.disabled : ""
            }`}
          >
            <span className={button.centerText}>Continue</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;

const VerificationInputs = ({
  onSubmitHandler,
  onResendHandler,
  otp,
  setOtp,
}: {
  otp: string;
  setOtp: Dispatch<SetStateAction<string>>;
  onSubmitHandler: () => void;
  onResendHandler: () => void;
}) => {
  const [canResend, setCanResend] = useState(false);

  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    if (seconds === 0) {
      setCanResend(true);
      return;
    }

    const interval = setInterval(() => {
      setSeconds((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  const formattedTime = `${String(Math.floor(seconds / 60)).padStart(
    2,
    "0"
  )}:${String(seconds % 60).padStart(2, "0")}`;

  const resend = () => {
    setCanResend(false);
    setSeconds(60);
    onResendHandler();
  };
  return (
    <>
      <VerificationCodeInput
        value={otp}
        onChange={setOtp}
        onEnterClicked={onSubmitHandler}
      />

      {canResend ? (
        <p style={{ cursor: "pointer" }} onClick={resend}>
          Resend Code
        </p>
      ) : (
        <p>
          Resend In <span>{formattedTime}</span>
        </p>
      )}
    </>
  );
};

type CustomOtpInputProps = {
  value: OTPInputProps["value"];
  onChange: OTPInputProps["onChange"];
  error?: string;
  onEnterClicked: () => void;
};

export function VerificationCodeInput({
  onChange,
  value,
  error,
  onEnterClicked,
}: CustomOtpInputProps) {
  const keydownHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === "Enter") {
      onEnterClicked();
    }
  };
  return (
    <>
      <div dir="ltr">
        <OtpInput
          placeholder="------"
          value={value}
          onChange={onChange}
          inputType="number"
          numInputs={6}
          onPaste={(ev) => {
            const data = ev.clipboardData.getData("text");
            onChange(data);
          }}
          renderSeparator={<span style={{ width: "0.5rem" }}></span>}
          renderInput={(props) => (
            <input
              {...props}
              onKeyDown={(evt) => keydownHandler(evt)}
              className={styles.otpInput}
            />
          )}
        />
      </div>
    </>
  );
}

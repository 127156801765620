import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Box, TableContainer } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Loader from "core/Loader";

type GenericTableType = {
  rows: TableRowsType[];
  heads: TableHeadType[];
  onRowClick?: (id: number) => void;

  loading?: boolean;
};
type TableRowType = string | any;
type TableRowsType = {
  [key: string]: TableRowType;
};
export type TableHeadType = {
  key: string;
  name: string | JSX.Element;
  align?: "right" | "left" | "center";
  permission?: string;
  action?: (id: number) => void;

  renderCell?: (row: TableRowType) => JSX.Element;
};
const GenericTable = ({
  heads,
  rows,
  loading,
  onRowClick,
}: GenericTableType) => {
  return loading || rows.length > 0 ? (
    <TableContainer>
      {!loading && (
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {heads.map((head) => (
                <TableCell align={head.align}>{head.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow
                sx={{ cursor: onRowClick ? "pointer" : "auto" }}
                key={index}
              >
                {heads.map((head) => {
                  if (
                    head.renderCell &&
                    (row[head.key] || row[head.key] === 0)
                  ) {
                    return (
                      <TableCell
                      onClick={
                        head.action
                          ? () => head.action?.(row.id)
                          : () => onRowClick?.(row.id)
                      }
                        align={head.align}
                      >
                        {head.renderCell(row[head.key])}
                      </TableCell>
                    );
                  }
                  if (row[head.key] || row[head.key] === 0)
                    return (
                      <TableCell
                        onClick={
                          head.action
                            ? () => head.action?.(row.id)
                            : () => onRowClick?.(row.id)
                        }
                        align={head.align}
                      >
                        {row[head.key]}
                      </TableCell>
                    );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {loading && <Loader />}
    </TableContainer>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 400,
        gap: 2,
        fontSize: 20,
        color: "primary.main",
      }}
    >
      <FolderOpenIcon />
      There is no data to display !
    </Box>
  );
};

export default GenericTable;

import { IconButton, Menu } from '@mui/material';
import React, { PropsWithChildren } from 'react'
import MoreVertIcon from "@mui/icons-material/MoreVert";

function TableMenu({children}:PropsWithChildren) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
  return (
    <div>
    <IconButton
     id={'mnue'}
     onClick={handleClick}
    >
      <MoreVertIcon  />
    </IconButton>
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'mnue',
      }}
      
    >
      {children}
    </Menu>
  </div>
  )
}

export default TableMenu
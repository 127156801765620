import styles from "./users.module.css";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TableContainer,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import shared from "styles/shared.module.css";
import SearchIcon from "@mui/icons-material/Search";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {
  captureOrder,
  getAvailableBranches,
  getPendingOrders,
} from "datasource/Api";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader from "core/Loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import WithCheckPermission from "components/WithCheckPermission";
import { PERMISSIONS } from "config/permissions";
import { dateFormatter, formatAMPM } from "utils";
import { ChangeEvent, useState } from "react";
import { OrderModel, OrderPlace } from "datasource/Types";
import { Pagination } from "components/pagination";
import { PER_PAGE_DEFAULT } from "config/base";

import { BasicModal } from "shared/modal";
import CaptureModalConfirm from "components/modals/captureModalConfirm/CaptureModalConfirm";
import usePermissionsChecker from "utils/hooks/usePermissionsChecker";
import DateRangeInput from "components/inputs/DateRangeInput";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { getFormatedDate } from "utils/date";
import TableMenu from "components/menu/TableMenu";
const PendingOrders = () => {
  const [search, setSearch] = useState("");
  const [orderPlace, setOrderPlace] = useState("ALL");
  const [branch, setBranch] = useState("ALL");
  const [page, setPage] = useState(1);
  const [date, setDate] = useState<DateRange<dayjs.Dayjs>>([null, null]);
  const [selected, setSelected] = useState<OrderModel>();
  const [amount, setAmount] = useState<number | null>(null);

  const { data: branches } = useQuery({
    queryKey: ["available-branches"],
    queryFn: () => getAvailableBranches(),
  });
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["pending-orders", search, orderPlace, branch, page, [...date]],
    queryFn: () =>
      getPendingOrders({
        pagingData: { page: page, perPage: PER_PAGE_DEFAULT },
        filters: {
          search,
          type: orderPlace,
          branch_id: branch,
          from_date: getFormatedDate(date[0]?.toDate()),
          to_date: getFormatedDate(date[1]?.toDate()),
        },
      }),
  });
  const {
    isPending,
    mutate: mutateCaptureOrder,
    isSuccess,
    reset,
  } = useMutation({
    mutationFn: captureOrder,

    onSuccess(data) {
      refetch();
      toast.success(`Orders was captured successfully`, {
        autoClose: 2000,
        position: "bottom-right",
      });
    },
  });

  const navigate = useNavigate();
  const handleSearchChanged = (event: any) => {
    const search = event.target.value;
    setSearch(search);
    setPage(1);
  };
  const handleOrderPlaceChanged = (event: SelectChangeEvent) => {
    setOrderPlace(event.target.value);
    setPage(1);
    setBranch("");
  };
  const handleBranchChanged = (value: string) => {
    setBranch(value);
    setPage(1);
  };
  const pageChanged = (event: ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };
  const { hasPermissionTo } = usePermissionsChecker();
  const handleDateChanged = (date: any) => {
    setDate(date);
    setPage(1);
  };

  const rowCount = data?.data?.data?.length ?? 0;
  return (
    <div className={shared.page}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {" "}
        <div className={shared.pageTitle}> Pending Orders</div>{" "}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        mb="50px"
        gap={"10px"}
        justifyContent="space-between"
      >
        <TextField
          sx={{ flex: 1 }}
          label="Search by key words"
          variant="outlined"
          placeholder="Search by client id name, order ID ..."
          value={search}
          size="small"
          onChange={handleSearchChanged}
          InputProps={{
            endAdornment: <SearchIcon style={{ color: "grey" }} />,
          }}
        />
        <DateRangeInput value={date} onChange={handleDateChanged} />

        <Select
          style={{ width: 150 }}
          labelId="Order type"
          size="small"
          onChange={handleOrderPlaceChanged}
          value={orderPlace}
        >
          <MenuItem value={"ALL"}>All types</MenuItem>
          <MenuItem value={OrderPlace.ONLINE}>Online</MenuItem>
          <MenuItem value={OrderPlace.STORE}>In-Store</MenuItem>
        </Select>
        <Autocomplete
          disablePortal
          id="merchant_id"
          options={branches?.data || []}
          size="small"
          style={{ width: 150 }}
          multiple={false}
          getOptionLabel={(op) => op.name}
          disabled={orderPlace === OrderPlace.ONLINE}
          onChange={(e, value) => {
            if (value) {
              handleBranchChanged(`${value?.id}`);
            }
          }}
          renderInput={(params) => <TextField {...params} label="Branch" />}
        />
      </Stack>
      <Paper sx={{ padding: "20px" }}>
        <>
          <TableContainer>
            {!isLoading && (
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Order ID</TableCell>
                    <TableCell align="left">Deema Order ID</TableCell>

                    <TableCell align="left">Date and time</TableCell>
                    {/* <TableCell align="left">Payment Plan</TableCell> */}
                    <TableCell align="left">Customer Info</TableCell>
                    <TableCell align="left">branch</TableCell>
                    <TableCell align="left">Amount{"(s)"}</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.data.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="left">
                        {row.merchant_order_id}
                      </TableCell>
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">
                        <div>{dateFormatter(row.order_date_time)}</div>
                        <div className={styles.thin}>
                          {formatAMPM(row.order_date_time)}
                        </div>
                      </TableCell>

                      {/* <TableCell align="left">
                        {
                          <div className={tagClass(row.payment_plan)}>
                            {row.payment_plan}
                          </div>
                        }
                      </TableCell> */}
                      <TableCell align="left">
                        <div>{row.customer_name}</div>
                        <div className={styles.thin}>
                          {row.customer_phone_number ? "+" : ""}
                          {row.customer_phone_number}
                        </div>
                      </TableCell>
                      <TableCell align="left">{row.branch_name}</TableCell>
                      <TableCell align="left">
                        {row.amount} {row.currency_code}
                      </TableCell>
                      <TableCell
                        sx={{ display: "flex", gap: 1 }}
                        align="center"
                      >
                        <TableMenu>
                          <>
                            {hasPermissionTo(PERMISSIONS.refund_purchase) && (
                              <MenuItem
                                onClick={() => {
                                  setSelected(row);
                                }}
                              >
                                <Typography color="primary.main">
                                  capture
                                </Typography>
                              </MenuItem>
                            )}

                            <MenuItem
                              onClick={() => navigate(`/activities/${row.id}`)}
                            >
                              <Typography color="success">
                                activities
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              onClick={() => navigate(`/pending-orders/${row.id}`)}
                            >
                              <Typography color="success">
                                details
                              </Typography>
                            </MenuItem>
                          </>
                        </TableMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {isLoading && <Loader />}
          </TableContainer>
        </>
        <Box className={shared.paging}>
          <div className={shared.showingResults}>
            {data?.data?.data.length} results out of {data?.data?.total}
          </div>
          <Pagination
            defaultPage={page}
            count={data?.data?.last_page || 0}
            onChange={pageChanged}
          />
        </Box>
      </Paper>
      <BasicModal
        open={!!selected}
        closeOnClickOutside
        closeText={isSuccess ? "Ok" : "Cancel"}
        onClose={() => {
          setSelected(undefined);
          reset();
        }}
      >
        <CaptureModalConfirm
          orderId={selected?.id ?? 0}
          confirmed={isSuccess}
          amount={amount}
          onAmountChange={setAmount}
          submitting={!selected || isPending || (+!amount > selected?.amount)}
          onConfirm={() => {
            if (selected) {
              mutateCaptureOrder({ amount, purchasesId: selected?.id });
            }
          }}
        />
      </BasicModal>
    </div>
  );
};

export default WithCheckPermission(PendingOrders, PERMISSIONS.view_purchase);

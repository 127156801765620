import { createBrowserRouter } from "react-router-dom";
import { Home } from "pages/home";
import { Layout } from "core/layout";
import { Orders } from "pages/orders";
import { Login } from "pages/login";
import { Webhook } from "pages/webhook";
import { Sattlments } from "pages/sattlments";
import { OrderDetails } from "pages/orderDetails";
import { Wallets } from "pages/wallets";
import { WalletTransactions } from "pages/walletTransactions";
import SetPassword from "pages/login/SetPassword";
import GenerateQRCodePage from "pages/createPayment/GenerateQRCodePage";
import { Branches } from "pages/branchs";
import CreateBranch from "pages/branchs/CreateBranch";
import UpdateBranch from "pages/branchs/UpdateBranch";
import { Users } from "pages/users";
import CreateUser from "pages/users/CreateUser";
import UpdateUser from "pages/users/UpdateUser";
import { Roles } from "pages/roles";
import CreateRole from "pages/roles/CreateRole";
import UpdateRole from "pages/roles/UpdateRole";
import { PendingOrders } from "pages/pendingOrders";
import { PaymentActivities } from "pages/payment-activities";
import ForgetPassword from "pages/login/forget-password";

export const router = createBrowserRouter([
  {
    element: <Layout />,
    path: "/",
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/orders",
        element: <Orders />,
      },
      {
        path: "/pending-orders",
        element: <PendingOrders />,
      },
      {
        path: "/pending-orders/:id",
        element: <OrderDetails />,
      },
      {
        path: "/settlements",
        element: <Sattlments />,
      },
      {
        path: "/orders/:id",
        element: <OrderDetails />,
      },
      {
        path: "/webhook",
        element: <Webhook />,
      },
      {
        path: "/wallets",
        element: <Wallets />,
      },
      {
        path: "/create-order",
        element: <GenerateQRCodePage  />,
      },
     
      {
        path: "/wallets/:id",
        element: <WalletTransactions />,
      },
      {
        path: "/branches",
        element: <Branches />,
      },
      {
        path: "/branches/new",
        element: <CreateBranch />,
      },
      {
        path: "/branches/:id",
        element: <UpdateBranch />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/users/new",
        element: <CreateUser />,
      },
      {
        path: "/users/:id",
        element: <UpdateUser />,
      },


      {
        path: "/roles",
        element: <Roles />,
      },
      {
        path: "/roles/new",
        element: <CreateRole />,
        
      },
      {
        path: "/roles/:id",
        element: <UpdateRole />,
      },
      {
        path: "/activities/:id",
        element: <PaymentActivities />,
      },
    ],
  },
  {
    element: <Login />,
    index: true,
    path: "/login",
  },
  {
    element: <SetPassword />,
    index: true,
    path: "/set_password",
  },
  {
    element: <ForgetPassword />,
    index: true,
    path: "/forget-password",
  },
]);

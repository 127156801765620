export type ResponseModel<T> = {
  message: string;
  status: boolean;
  data: T;
  result_code: number;
};
export type BranchModel = {
  name: string;
  id: number;
  merchant_id: number;
  is_hidden: number;
  merchant_reference: string;
};
export type CreateBranchType = {
  name: string;
  merchant_reference?: string | undefined;
};

export type BranchUserModel = {
  full_name: string;
  id: number;
  is_owner: number;
  is_active: number;
  email: string;
  branches: BranchModel[];
  roles: RoleModel[];
};
export type CreateUserType = {
  full_name: string;
  email: string;
  role_id?: number | string;
  branches: number[];
};

export type PermissionType = {
  key: string;
  description: string;
  guard_name: string;
  name: string;
  id: number;
};
export type RoleModel = {
  name: string;
  id: number;
  is_default: number;
  permissions: PermissionType[];
};
export type CreateRoleType = {
  name: string;
  permissions: number[];
};

export type LoginRequestModel = {
  email: string;
  password: string;
};
export type GetSattlmentDetailsRequestModel = {
  settlementId: number;
  filters?: {
    transaction_type?: string;
    transaction_action?: string;
  };
};

export type VerifyForgetPasswordModel = {
  otp: string;
  token: string;
};
export type ForgetPasswordSetPasswordsModel = {
  token: string;
  password: string;
  password_confirmation: string;
};

export type AuthModel = {
  user: UserModel | null;
  accessToken: string;
  is_aggregator: number;
};
export type UserDetailsModel = {
  email: string;
  full_name: string;
  id: number;
  branches: BranchModel[];
  roles: RoleModel[];
};

export type UserModel = {
  id: number;
  name: string;
  email: string;
};

export enum OrderStatus {
  CANCELLED = "cancelled",
  EXPIRED = "expired",
  CREATED = "order_created",
  CAPTURED = "captured",
  FULLY_CAPTURED = "fully_captured",
  PARTIALLY_CAPTURED = "partially_captured",
  PARTIALLY_REFUNDED = "partially_refunded",
  FULLY_REFUNDED = "fully_refunded",
  ORDER_FULLY_PAID = "order_fully_paid",
  ORDER_DECLINED = "order_settled",
  ORDER_SETTLED = "order_declined",
  PENDING = "pending",
  DECLINED = "declined",
}
export enum ActivityStatus {
  CANCELLED = "cancelled",
  EXPIRED = "expired",
  CREATED = "order_created",
  CAPTURED = "captured",
  FULLY_CAPTURED = "order_fully_captured",
  PARTIALLY_CAPTURED = "order_partially_captured",
  PARTIALLY_REFUNDED = "order_partially_refunded",
  FULLY_REFUNDED = "order_fully_refunded",
  ORDER_FULLY_PAID = "order_order_fully_paid",
  ORDER_DECLINED = "order_order_settled",
  ORDER_SETTLED = "order_order_declined",
  PENDING = "pending",
}
export enum OrderPlace {
  STORE = "STORE",
  ONLINE = "ONLINE",
}
export enum installmentStatus {
  COMPLATED = "completed",
  CANCELLED = "cancelled",
  EXPIRED = "expired",
  PENDING = "pending",
}
export type OrderDetails = {
  id: number;
  merchant_id: number;
  customer_id: number;
  is_captured_by_merchant: number;
  plan_id: number;
  amount: number;
  merchant_order_id: number;
  currency_code: string;
  reference: string;
  deleted_at: null | string;
  created_at: string;
  updated_at: string;
  status: string;
  merchant_urls: {
    failure: string;
    success: string;
  };
  installments_count: number;
  completed_installments_count: number;
  settled_status: string;
  total_refunds: number;
  merchant: {
    id: number;
    name: string;
    avg_order_value: string;
    annual_revenue: string;
    category_id: number;
    phone_number: string;
    business_name: string;
    status: string;
    created_at: string;
    email: string;
  };
  customer: {
    id: number;
    phone_number: string;
    image: string;
    email: string;
    date_of_birth: string;
    secondary_mobile_number: null | string;
    address: null | string;
    payment_customer_id: null | string;
    first_name: string;
    last_name: string;
    is_verified: boolean;
  };
  plan: {
    id: number;
    name: string;
    metadata: string[];
    nick_name: string;
  };
  installments: {
    id: number;
    purchase_id: number;
    amount: number;
    status: string;
    completed_at: null | string;
    deleted_at: null | string;
    created_at: string;
    updated_at: string;
    must_be_paid_at: string;
  }[];
  refunds: any[]; // Define the type for refunds accordingly if needed.
  charges: any[]; // Define the type for charges accordingly if needed.
  next_payment_due: {
    purchase_id: number;
    must_be_paid_at: string;
  };
};

export enum SattlmentStatus {
  SATTLED = "sattled",
  PENDING = "pending",
}

export enum PaymentPlan {
  ONE_PAYMENT = "pay in 30",
  THREE_PAYMENTS = "split in 3",
}

export type GetOrderesRequestModel = {
  pagingData: PagingData;
  filters?: OrderesRequestFilter;
};
export type GetOrdersActivitiesRequestModel = {
  pagingData: PagingData;
  id?: string;
};

export type GetSattlmentsRequestFilter = {
  pagingData: PagingData;
  filters?: SattlmentsRequestFilter;
};

export type PagingData = {
  perPage: number;
  page: number;
};

export type OrderesRequestFilter = {
  search?: string;
  order_status?: string;
  type?: string;
  branch_id?: string;
  from_date?: string | null;
  to_date?: string | null;
};

export type SattlmentsRequestFilter = {
  search?: string;
  sattlment_type?: string;
  from_date?: string | null;
  to_date?: string | null;
};

export type PagingModel<T> = {
  current_page: number;
  data: T[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: any[];
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: null;
  to: number;
  total: number;
};
export type OrderPlaceType = "ONLINE" | "STORE" | "ALL";
export type OrderModel = {
  id: number;
  merchant_order_id: number;
  payment_plan: PaymentPlan;
  customer_name: string;
  branch_name: string;
  customer_phone_number: string;
  amount: number;
  currency_code: string;
  order_date_time: string;
  status: OrderStatus;
};
interface Actor {
  id: number;
  full_name_en?: string;
  name?: string;
  avg_order_value: string;
  annual_revenue: string;
  category_id: number;
  phone_number: string;
  business_name: string;
  status: string;
  created_at: string;
  merchant_logo: string;
  merchant_website: string;
  business_type: string;
  country_id: number;
  is_hidden: number;
  deema_commission: number;
  currency: string;
  background: string;
  show_onboarding: number;
  min_credit_limit: number;
  max_credit_limit: number;
  cover: string;
  description: string | null;
  auto_capture: number;
}
export enum ACTOR_TYPE {
  CUSTOMER = "App\\Models\\Customer\\Models\\Customer",
  MERCHANT = "App\\Models\\Merchant\\Models\\Merchant",
}
// Interface for the main Transaction object
export interface Transaction {
  id: number;
  purchase_id: number;
  type: string;
  activity_amount: number;
  amount_before_action: number;
  amount_after_action: number;
  created_at: string;
  actor_type: string;
  updated_at: string;
  deleted_at: string | null;
  actor: Actor;
}
export type WalletsModel = {
  merchant_id: number;
  wallet_id: number;
  currency: string;
  wallet_created_at: string;
  merchant_business_name: string;
};
export type WalletTransactionsModel = {
  merchant_id: number;
  wallet_id: number;
  currency: string;
  merchant_business_name: string;
  transaction_id: number;
  transaction_type: string;
  transaction_action_type: string;
  transaction_amount: number;
  transaction_created_at: string;
};
export enum Currency {
  AED = "AED",
  USD = "USD",
}

export enum SattlmentType {
  LOCAL = "local",
}

export type SattlmentModel = {
  id: number;
  merchant: string;
  requested_at: string;
  wallet: Currency;
  settlement_type: SattlmentType;
  amount: number;
  fee: number;
  status: SattlmentStatus;
  net_amount: number;
};

export type DashboardModel = {
  total_users: number;
  total_revenue: number;
  daily_revenue: number;
  monthly_revenue: number;
  currency: string;
};
export type PlaceOrderRes = {
  data: {
    order_reference: string;
    redirect_link: string;
    purchase_id: number;

    currency: string;
  };
};

export type WebhookInfo = {
  webhook_url: string;
  header_key: string;
  header_value: string;
};

export type UpdateWebhookRequestModel = {
  webhookUrl: string;
  headerKey: string;
  headerValue: string;
};

export enum MerchantStatus {
  PENDING = "pending",
  REJECTED = "rejected",
  APPROVED = "approved",
  INITIAL_APPROVED = "initially_approved",
}

export enum FileStatus {
  PENDING = "pending",
  REJECTED = "rejected",
  APPROVED = "approved",
}

export type OrderPaging = PagingModel<OrderModel>;
export type OrderActivitiesPaging = PagingModel<Transaction>;
